<template>
  <div class="c_online_test">
    <div class="stu-module-header">
      <div class="stu-module-title">在线考试</div>
    </div>

    <el-form
      :model="form"
      inline
      class="c_online_test_form"
      ref="onlineTestForm"
      size="medium"
    >
      <el-form-item label="考试名称" prop="examName">
        <el-input v-model="form.examName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="作答时间" prop="date">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          format="yyyy/MM/dd"
          value-format="yyyy/MM/dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="query" type="primary">查询</el-button>
        <el-button @click="reset('onlineTestForm')">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" :height="height" v-loading="loading">
      <el-table-column label="序号" width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(c, i) in col"
        :prop="c.prop"
        :label="c.label"
        :key="i"
        align="center"
        :min-width="c.width"
        :show-overflow-tooltip="c.tooltip"
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="80">
        <template slot-scope="scope">
          <el-button type="text" @click="detail(scope.row)">进入</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  QueryStudentExam,
  QueryStudentExamRecordDetails,
} from "@/libs/api/online";
export default {
  name: "c_online_test",
  data() {
    return {
      height: window.innerHeight - 380,
      loading: false,
      form: {
        examName: "",
        date: [],
      },
      col: [
        { prop: "examName", label: "考试名称", width: 120 },
        { prop: "examDuration", label: "考试时长" },
        { prop: "reviewStatus", label: "批阅状态" },
        {
          prop: "answerStartTime",
          label: "开始时间",
          width: 120,
          tooltip: true,
        },
        { prop: "answerEndTime", label: "结束时间", width: 120, tooltip: true },
      ],
      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    datalist() {
      return this.tableData.slice(
        (this.current - 1) * this.pageSize,
        this.current * this.pageSize
      );
    },
    course() {
      return this.$store.getters.getStuMyCourse;
    },
  },
  watch: {
    course(n) {
      n && this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    reset(refName) {
      this.$refs[refName].resetFields();
    },
    init() {
      const params = {
        page: this.current,
        pageSize: this.pageSize,
      };
      let data = new FormData();
      data.append("courseId", this.course.id);
      this.form.examName && data.append("info", this.form.examName);
      this.form.date[0] &&
        data.append("startTime", `${this.form.date[0]} 00:00:00`);
      this.form.date[1] &&
        data.append("endTime", `${this.form.date[1]} 00:00:00`);
      this.loading = true;
      QueryStudentExam(params, data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    query() {
      this.init();
    },

    handleCurrentChange(page) {
      if (page) this.current = page;
    },

    detail(row) {
      if (row) {
        if (row.shortDuration || row.shortDuration === 0) {
          sessionStorage.setItem(
            "stu_exam_shortDuration",
            JSON.stringify(row.shortDuration)
          );
        }
        this.$router.push({
          path: "test/detail",
          query: {
            id: row.examSetId,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.c_online_test {
  background: #fff;
  padding: 20px;
  .c_online_test_form {
    margin-top: 40px;
  }
}
</style>